import { useEffect, useRef, useState } from "react";
import Image from "next/image";

import useWindowSize from "../../hooks/useWindowSize";
import {
  AnimationType,
  ImageType,
  isAvailable,
  LayoutType,
  LinkType,
} from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import { SvgIcon } from "../Global/SvgIcon";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { motion } from "framer-motion";
import { blur_opacity_variant } from "../../utils/transition";
import { WP_Post, WP_Term } from "wp-types";
import Link from "next/link";

interface LogosLayoutType extends LayoutType {
  logos_pos: "in-container" | "fullwidth" | "grid-multiline";
}

export type LogosModuleType = {
  acf_fc_layout: "logos";
  logos_type: "manual-logos" | "categories-logos" | "multiselect-logos";
  logos: LogoType[];
  layout: LogosLayoutType;
  logos_categories: WP_Term[];
  logos_multiselect: WP_Post[];
};

export type LogoType = {
  logo_type: "svg" | "image";
  image: ImageType | false;
  svg: ImageType | false;
  "svg-animation": AnimationType | false;
  link?: LinkType;
};

interface LogosProps {
  data: LogosModuleType;
  LogosData: LogoType[];
}

const Logos = ({ data, LogosData }: LogosProps) => {
  const { background, abstand, logos_pos } = data.layout;
  const gap = abstand === "default" ? "" : abstand;
  const { width } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containterWidth, setcontainterWidth] = useState<number>(0);

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    if (containerRef.current) {
      timeOutId = setTimeout(() => {
        if (containerRef.current)
          setcontainterWidth(containerRef.current.offsetWidth);
      }, 500);
    }

    return () => clearTimeout(timeOutId);
  }, []);

  const hideContainer = containterWidth >= width;

  const fullwidth =
    logos_pos === "fullwidth" ||
    (logos_pos === "in-container" && hideContainer);

  let mainJSX;
  switch (logos_pos) {
    case "in-container":
      mainJSX = (
        <>
          <div
            style={{
              display: hideContainer ? "block" : "none",
            }}
          >
            <LogosSlider elements={LogosData} />
          </div>
          <div
            className=" is-justify-content-center"
            style={{
              display: hideContainer ? "none" : "flex",
            }}
          >
            <div ref={containerRef} className="grid-container">
              {LogosData.map((logo, i) => {
                const mediaJSX = MediaJSXGen(logo);
                const link = logo.link;
                return (
                  <div key={i} className="grid">
                    <div className="media-wrapper">{mediaJSX}</div>
                    {link && (
                      <Link
                        href={link.url}
                        target={link.target}
                        className="logo-link"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      );

      break;
    case "grid-multiline":
      mainJSX = (
        <div className="is-flex is-justify-content-center">
          <div className="grid-multiline">
            {LogosData.map((logo, i) => {
              const mediaJSX = MediaJSXGen(logo);
              const link = logo.link;
              return (
                <div key={i} className="grid with-bg">
                  <div className="media-wrapper">{mediaJSX}</div>
                  {link && (
                    <Link
                      href={link.url}
                      target={link.target}
                      className="logo-link"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
      break;
    case "fullwidth":
      mainJSX = <LogosSlider elements={LogosData} />;
      break;
    default:
      mainJSX = <></>;
      break;
  }

  return (
    <Section
      disableColumns
      disableContainer={fullwidth}
      className={`logos-mod  ${background} ${gap} ${fullwidth ? "px-0" : ""}`}
    >
      {mainJSX}
    </Section>
  );
};

const MediaJSXGen = (logo: LogoType) => {
  const type = logo.logo_type;
  let mediaJSX;
  if (type === "image") {
    const image = isAvailable<ImageType>(logo.image) ? logo.image : null;

    mediaJSX = image ? (
      <Image
        src={image.url!}
        alt={image.alt}
        data-title={image.title}
        fill
        style={{
          objectFit: "contain",
          objectPosition: "center",
        }}
        sizes="(max-width: 768px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 20vw, 17vw"
      />
    ) : (
      <></>
    );
  } else if (type === "svg") {
    const animation = isAvailable<AnimationType>(logo["svg-animation"])
      ? logo["svg-animation"]
      : undefined;

    const svg = isAvailable<ImageType>(logo.svg) ? logo.svg : undefined;

    mediaJSX = <SvgIcon svg={svg} svg_animation={animation} />;
  } else {
    mediaJSX = <></>;
  }

  return mediaJSX;
};

export default Logos;

interface LogosSliderProp {
  elements: LogoType[];
}
const LogosSlider = ({ elements }: LogosSliderProp) => {
  return (
    <motion.div variants={blur_opacity_variant} className="is-clipped">
      <Splide
        className="logos-slider"
        options={{
          perPage: 5,
          perMove: 1,
          type: "loop",
          gap: "1rem",
          pagination: false,
          arrows: false,
          slideFocus: true,
          rewind: false,
          autoHeight: true,
          intersection: {
            inView: {
              autoplay: true,
            },
            outView: {
              autoplay: false,
            },
          },
          breakpoints: {
            375: {
              perPage: 2,
            },
            768: {
              perPage: 3,
            },
            1024: {
              perPage: 5,
            },
          },
        }}
        aria-labelledby="logos-slider"
        extensions={{ Intersection, AutoScroll }}
      >
        {elements.map((element, index) => {
          const mediaJSX = MediaJSXGen(element);
          const link = element.link;
          return (
            <SplideSlide role="logo-slide" key={index}>
              {mediaJSX}
              {link && (
                <Link
                  href={link.url}
                  target={link.target}
                  className="logo-link"
                />
              )}
            </SplideSlide>
          );
        })}
      </Splide>
    </motion.div>
  );
};
